<script setup lang="ts">

import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";

const { pushSuccess, pushError } = useNotificationsEx();
const { required, minLength } = customValidators();

const runtimeConfig = useRuntimeConfig();

const friendlyCaptcha: any = ref(null);

const message = reactive({
  message: "",
  captcha: false,
});

const rules = computed(() => ({
  message: {
    required,
    minLength: minLength(20),
  }
}));

const $v = useVuelidate(rules, message);
const { triggerFriendlyCaptcha, focusOnFirstInvalidField } = useFormHelpers($v)
const loading = ref(false);

const onSubmit = async (e: Event) => {
  e.preventDefault();

  if(loading.value) return;
  loading.value = true;  

  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid && (await triggerFriendlyCaptcha(message))) {

    try {
      const response = await $fetch(`/api/youranonymousmessage`, {
        method: "post",
        body: message,
        headers: {
          "Content-Type": "application/json; charset: UTF-8",
          Accept: "application/json; charset: UTF-8",
        },
      });

      if (response  && response?.state === "error") {
        pushError(response.statusMessage);
      }
      else 
      {
        message.message = "";
        pushSuccess("Vielen Dank für Ihre Nachricht!");
      }

    } catch (err: any) {
      pushError("Die Anfrage konnte nicht bearbeitet werden.");
    }

    $v.value.$reset();
    
    message.captcha = false;

    friendlyCaptcha.value.recreate();
  } else {
    pushError("Überprüfen Sie Ihre Eingaben, sie sind entweder fehlerhaft oder fehlen..");
    focusOnFirstInvalidField();
  }

  loading.value = false;
};

const friendlyCaptchaChange = (state: any) => {
  message.captcha = state !== false ? state : false;
};
</script>

<template>
  <form method="post" @submit.prevent="onSubmit">
    <fieldset>
      <legend class="mb-4 text-h3 text-maas-typography-headline-primary">Ihre Nachricht</legend>
      <SharedFormTextarea
        id="message"
        v-model="message.message"
        class="h-60 w-full"
        :valid="!$v.message.$invalid || !$v.message.$dirty"
        :error="$v.message?.$silentErrors?.[0]?.$message ? String($v.message.$silentErrors[0].$message) : ''"
        @change="$v.message.$touch()"
      />
    </fieldset>

    <fieldset class="flex flex-wrap gap-8 items-start justify-between">
      <VueFriendlyCaptcha
          class="mb-4 mt-4"
          ref="friendlyCaptcha"
          :sitekey="runtimeConfig.public.FRIENDLY_CAPTCHA_SITEKEY"
          puzzle-endpoint__="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
          language="de"
          @done="friendlyCaptchaChange"
          @error="friendlyCaptchaChange(false)"
      />

      <SharedFormButton class="w-full sm:w-auto mt-4" layout="primary" size="big" type="submit" :loading="loading" :disabled="loading">
        Jetzt absenden
      </SharedFormButton>
    </fieldset>
  </form>
</template>
